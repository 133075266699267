import { LOGIN, LOGIN_ERROR, GENERATE_TOKEN } from "../actionTypes";
import { loginApi, generateTokenApi } from "../../../api/ApiService";

export const addLogInData = (data) => {
  return (dispatch) => {
    loginApi(data)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch({ type: LOGIN, payload: response });
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: LOGIN_ERROR, payload: error?.response?.data });
        throw error;
      });
  };
};

export const generateTokenData = () => {
  return async (dispatch) => {
    try {
      const response = await generateTokenApi();
      dispatch({ type: GENERATE_TOKEN, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: GENERATE_TOKEN, payload: error.response.data });
      throw error;
    }
  };
};

export const logout = () => {
  return { type: "LOGOUT" };
};
