
import store from "../redux/store/store";
import { generateTokenApi } from "../api/ApiService";
import { logout } from "../redux/action/LoginAction/LoginAction";

export const validateToken = async () => {
  const state = store.getState();
  const accessTokenExpiry = state.loginData.loginSuccesData.accessTokenExpiry;
  
  const currentTime = Math.floor(new Date().getTime() / 1000);
  
  if (currentTime > accessTokenExpiry) {
    try {
      const response = await generateTokenApi();
      if (response?.data?.token?.accessToken) {
        store.dispatch({ type: 'GENERATE_TOKEN', payload: response });
        return response.data.token.accessToken;
      } else {
        store.dispatch(logout());
        return null;
      }
    } catch (error) {
      console.error("Error generating new token:", error);
      store.dispatch(logout());
      return null;
    }
  } else {
    return state.loginData.loginSuccesData.accessToken;
  }
};