import React, { useState, useEffect } from "react";
import id from "./../../assets/images/id.png";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import DeclineApproveId from "./DeclineApproveId";
import { useDispatch, useSelector } from "react-redux";
import {
  updateKycStatus,
  userKycManagmentData,
} from "../../redux/action/UserAction/UserAction";
import { approvelsAddAPiRequest } from "../../redux/action/Approvels/ApprovelsAction";

const ApproveModal = ({
  isModalOpen,
  onClick,
  isUserData,
  isCurrentPage,
  slectFilter,
  actionType,
}) => {
  const [isDeclineApprovalId, setIsDeclineApprovalId] = useState(false);
  const [successApiMsg, setSuccessApiMsg] = useState("");
  const [isFirst, setIsFirst] = useState(false);

  const dispatch = useDispatch();
  const kycStatusMessage = useSelector(
    (state) => state.userData.kycStatusMessage
  );
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const handleCloseModal = () => {
    console.log("modal close");
    onClick(false);
  };

  // Update success message when KYC status message changes
  useEffect(() => {
    if (isFirst && kycStatusMessage?.message) {
      setSuccessApiMsg(kycStatusMessage.message);
    } else {
      setSuccessApiMsg("");
    }
  }, [kycStatusMessage, isFirst]);

  // Toggle decline modal popup
  const handleDeclinePopup = () => {
    setIsDeclineApprovalId(!isDeclineApprovalId);
  };

  // Submit KYC approval
  // const submitUpdateKyc = () => {
  //   const formData = {
  //     userId: isUserData.userId,
  //     status: "approved",
  //   };
  //   setIsFirst(true);

  //   // Check for admin permissions
  //   if (adminPermission?.permissions?.[0]?.users?.kyc === "full_access") {
  //     dispatch(updateKycStatus(formData));
  //   } else {
  //     const approvelObj = {
  //       action: "Kyc",
  //       action_status: 0,
  //       comment: "Approve Kyc",
  //       details: {
  //         name: isUserData.name,
  //         userId: isUserData.userId,
  //         approvalId: isUserData.approvalId,
  //         submittedDate: isUserData.submittedDate,
  //         idType: isUserData.idType,
  //         idNumber: isUserData.idNumber,
  //         uploadedDocumentUrl: isUserData.uploadedDocumentUrl,
  //       },
  //     };
  //     dispatch(approvelsAddAPiRequest(approvelObj));
  //   }

  //   onClick(); // Close modal
  //   if (isCurrentPage) {
  //     dispatch(userKycManagmentData(isCurrentPage, slectFilter)); // Refresh KYC data
  //   }
  // };

  const submitUpdateKyc = (data) => {
    const formData = {
      userId: isUserData.userId,
      status: "approved",
    };

    setIsFirst(true);

    if (adminPermission?.permissions) {
      if (adminPermission.permissions[0].users.kyc === "full_access") {
        dispatch(updateKycStatus(formData));
      } else {
        let approvelObj = {
          action: " Approved Kyc",
          action_status: 0,
          comment: " ",
          details: {
            name: isUserData.name,
            userId: isUserData.userId,
            approvalId: isUserData.approvalId,
            submittedDate: isUserData.submittedDate,
            idType: isUserData.idType,
            idNumber: isUserData.idNumber,
            //"reason": "ertyuiop", // IN case of decline kyc
            uploadedDocumentUrl: isUserData.uploadedDocumentUrl,
          },
        };
        dispatch(approvelsAddAPiRequest(approvelObj));
      }
    } else {
      dispatch(updateKycStatus(formData));
    }
    onClick();
    if (isCurrentPage) {
      dispatch(userKycManagmentData(isCurrentPage, slectFilter));
    }
  };

  return (
    <div className="cust-close-div">
      <Modal
        isOpen={isModalOpen}
        // onRequestClose={onClick}
        onRequestClose={handleCloseModal}
        style={{
          content: {
            maxWidth: "500px",
            margin: "0 auto",
            overflow: "auto",
          },
        }}
      >
        {isDeclineApprovalId ? (
          <DeclineApproveId
            slectFilter={slectFilter}
            isCurrentPage={isCurrentPage}
            userItem={isUserData}
            onClick={() => setIsDeclineApprovalId(!isDeclineApprovalId)}
            removePreviousModal={onClick}
          />
        ) : (
          <div
            className="modal_height cust-Model-close"
            id="transModal"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered common-modal size-2">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Approval ID #{isUserData.approvalId}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onClick}
                  >
                    <MdClose />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="trans-details">
                    <div className="trans-details-left">
                      <div className="row modified">
                        <div className="col-lg-12">
                          {successApiMsg && (
                            <p className="form-text text-success">
                              {successApiMsg}
                            </p>
                          )}
                          <span className="popTitle">User</span>
                          <div className="sender mt-2">
                            <span className="sender-img">
                              <img
                                src={isUserData.profileImage || id}
                                alt="User"
                              />
                            </span>
                            <span className="sender-txt">
                              {isUserData.name} <br />{" "}
                              <a href="#">{isUserData.useremail}</a>
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6 tarnsDetails">
                          <span className="popTitle">ID Type</span>
                          <span className="popDesc">{isUserData.idType}</span>
                        </div>
                        <div className="col-sm-6 tarnsDetails">
                          <span className="popTitle">ID Number</span>
                          <span className="popDesc">{isUserData.idNumber}</span>
                        </div>
                        <div className="col-sm-12 tarnsDetails">
                          <div className="idUp">
                            <span>Uploaded image</span>
                            <img
                              src={isUserData.uploadedDocumentUrl || id}
                              alt="Uploaded Document"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 tarnsDetails">
                          <div className="row modified">
                            <div className="col-sm-6">
                              <button
                                className="btn btn-block green-btn"
                                type="button"
                                onClick={submitUpdateKyc}
                              >
                                <i className="icon-icon-tick" /> Approve
                              </button>
                            </div>
                            <div className="col-sm-6">
                              <button
                                className="btn btn-block red-btn"
                                type="button"
                                onClick={handleDeclinePopup}
                              >
                                <MdClose /> Decline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ApproveModal;
