import { GET_ALL_NAME_APPROVAL_API } from "../action/actionTypes";

const initialState = {
  NameApprovelsListData: {}, 
};

const NameApprovalData = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NAME_APPROVAL_API:
      return {
        ...state, 
        NameApprovelsListData: action.payload, 
      };

    default:
      return state;
  }
};

export default NameApprovalData;
