import store from "../redux/store/store";
import { LOGOUT } from "../redux/action/actionTypes";
import { fetchAdminProfile } from "../redux/action/AdminProfile/AdminProfileAction";

let isAdminBlocked = false;

export const checkAdminStatus = async () => {
  try {
    if (!isAdminBlocked) {
      // console.log("Fetching admin profile");

      await store.dispatch(fetchAdminProfile());

      await new Promise((resolve) => setTimeout(resolve, 500));

      const state = store.getState();

      const adminProfile = state.adminProfileData?.adminProfile;

      // console.log("Admin profile data:", adminProfile);

      if (
        adminProfile &&
        adminProfile.data.status === 0 &&
        adminProfile.data.role === "admin"
      ) {
        isAdminBlocked = true;
        // console.log("Admin is blocked. Logging out...");

        store.dispatch({ type: LOGOUT });

        window.location.href = "/login";
      }
    }
  } catch (error) {
    // console.error("Error checking admin status:", error);
  }
};

