import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import defaultUserImage from "./../../assets/images/alt-user-image.png";

import { getTransactionDetailsApiData } from "../../redux/action/TransactionAction/TransactionAction";
import { useDispatch, useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";
import { jsPDF } from "jspdf";
import { approvelsAddAPiRequest } from "../../redux/action/Approvels/ApprovelsAction";
import { renderToString, renderToStaticMarkup } from "react-dom/server";
import { GET_TRASACTION_DETAILS_API_EXPORT_CLEAR } from "../../redux/action/actionTypes";
import ExportData from "./export";
import moment from "moment";
import { MdClose } from "react-icons/md";

const TranstionIdModel = (props) => {
  const { transactionDataById } = props;
  const senderName = transactionDataById?.senderName;
  const receiverName = transactionDataById?.receiverName;
  const senderCompanyName = transactionDataById?.senderCompanyName;

  const senderCompanyAvtar =
    transactionDataById?.senderCompanyAvtar || defaultUserImage;

  const senderAccountType = transactionDataById?.senderAccountType;
  const receiverAccountType = transactionDataById?.receiverAccountType;
  const senderDisplayAccountType =
    senderAccountType === "Individual"
      ? transactionDataById.senderName
      : transactionDataById.senderCompanyName;

  const receiverDisplayAccountType =
    receiverAccountType === "Individual"
      ? transactionDataById.receiverName
      : transactionDataById.receiverCompanyName;



  const receiverAvtarr =
    receiverAccountType === "Individual"
      ? transactionDataById.receiverAvtar
      : transactionDataById.receiverCompanyAvtar;

  const senderAvtarr =
    senderAccountType === "Individual"
      ? transactionDataById.senderAvtar || defaultUserImage
      : transactionDataById.senderCompanyAvtar || defaultUserImage;

  const dispatch = useDispatch();

  const [invoiceD, setInvoiceD] = useState([]);
  const adminPermission = useSelector(
    (state) => state.loginData.loginSuccesData
  );
  const transactionDetail = useSelector(
    (state) => state.transactionData.transactionDetails
  );

  console.log(receiverAvtarr, "receiverAvtarr111111");
  console.log(senderAvtarr, "senderAvtarr111111");
  console.log(transactionDataById, "transactionDataById");

  useEffect(() => {
    dispatch(
      getTransactionDetailsApiData(props.transactionDataById.transactionId)
    );
  }, []);

  useEffect(() => {
    const arr = transactionDetail?.data?.invoiceDetails;

    if (arr === undefined) {
      setInvoiceD("0");
    } else {
      setInvoiceD(arr);
    }
  }, [transactionDetail]);

  const exportPDf = () => {
    const doc = new jsPDF("p", "pt", "a4");

    let a = renderToString(
      <ExportData
        data={transactionDetail.data?.transactionDetails}
        schoolData={transactionDetail.data?.paymentDetails}
      />
    );

    doc.html(a, {
      callback: function (doc) {
        doc.save("transactionDetails.pdf");
        dispatch({
          type: GET_TRASACTION_DETAILS_API_EXPORT_CLEAR,
          payload: "",
        });
        //window.open(doc.output('bloburl'))
      },
    });
  };
  const refundBtn = (con) => {
    const resData = {
      action: "Refund",
      action_status: 1,
      comment: "Refund amount ",
      details: transactionDetail?.data?.transactionDetails,
    };
    if (con === "Debited") {
      if (adminPermission?.permissions) {
        if (
          adminPermission.permissions[0].transactions.refund === "full_access"
        ) {
          dispatch(approvelsAddAPiRequest(resData));
        } else if (
          adminPermission.permissions[0].transactions.refund === "view_only"
        ) {
          return true;
        } else {
          dispatch(approvelsAddAPiRequest(resData));
        }
      } else {
        dispatch(approvelsAddAPiRequest(resData));
      }
    } else {
      return true;
    }
  };

  const desideClassName = () => {
    let css = "";
    if (transactionDetail?.data?.transactionDetails) {
      const {
        data: { transactionDetails },
      } = transactionDetail;
      const { transactionTimeStamp, transactionStatus } = transactionDetails;
      if (transactionTimeStamp && transactionStatus === "Debited") {
        const transactionTime = moment(transactionTimeStamp);
        // const transactionTime = moment('18/10/2022 11:36:00','DD/MM/YYYY HH:mm:ss');
        const currentTime = moment();
        const timeStatus = currentTime.diff(transactionTime, "hours");
        if (timeStatus >= 24) {
          css += " activeBtnColor";
        } else {
          css += " disabled";
        }
      } else {
        css += " disabled";
      }
    }

    return css;
  };

  return (
    <Modal isOpen={props.isModalOpen} onRequestClose={() => props.onClick()}>
      <div>
        <div className="transition_modalpage" id="transModal" tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered common-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Transaction ID #
                  {transactionDetail &&
                    transactionDetail?.data?.transactionDetails?.transactionId}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onClick()}
                >
                  <MdClose />
                </button>
              </div>
              <div className="modal-body">
                <div className="trans-details">
                  <div className="row modified">
                    <div className="col-sm-9">
                      <div className="trans-details-left">
                        <span className="transDate">
                          {transactionDetail &&
                            transactionDetail?.data?.transactionDetails
                              ?.transactionDate}
                        </span>
                        <span className="transName">
                          GH₵{" "}
                          {transactionDetail &&
                            Number(
                              transactionDetail?.data?.transactionDetails
                                ?.amount
                            ).toFixed(2)}
                          {/* .00 */}
                        </span>
                        <span className="transStat">
                          {transactionDetail &&
                            transactionDetail?.data?.transactionDetails
                              ?.transactionStatus}
                        </span>

                        <div className="row modified">
                          <div className="col-lg-6 tarnsDetails">
                            <span className="popTitle">Sender</span>
                            <div className="sender">
                              <span className="sender-img">
                                <img
                                  src={
                                   
                                    senderAvtarr || defaultUserImage
                                  }
                                  alt=""
                                />
                              </span>
                              
                              <span className="sender-txt">
                                {
                                  
                                  senderDisplayAccountType
                                }
                                <br />{" "}
                                <a href="#">
                                  {
                                    transactionDetail?.data?.transactionDetails
                                      ?.useremail
                                  }
                                </a>
                                         
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6 tarnsDetails">
                            <span className="popTitle">Receiver</span>
                            <div className="sender">
                              <span className="sender-img-comp">
                                <img
                                  src={receiverAvtarr || defaultUserImage}
                                  alt=""
                                />
                              </span>
                              <span className="sender-txt">
                             
                                {receiverDisplayAccountType}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-6 tarnsDetails">
                            <span className="popTitle">Method</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.method}
                            </span>
                          </div>
                          <div className="col-sm-6 tarnsDetails">
                            <span className="popTitle">Type</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.type}
                            </span>
                          </div>

                          <div className="col-sm-6 tarnsDetails">
                            <span className="popTitle">Payment details</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.paymentThrough}
                            </span>
                          </div>
                          <div
                            className="col-sm-6 tarnsDetails"
                            style={{
                              display:
                                transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.type === "Schools"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <span className="popTitle">Institute</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.paymentDetails?.data
                                  ?.institute}
                            </span>
                          </div>
                          <div
                            className="col-sm-6 tarnsDetails"
                            style={{
                              display:
                                transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.type === "Schools"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <span className="popTitle">Location</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.paymentDetails?.data
                                  ?.location}
                            </span>
                          </div>
                          <div
                            className="col-sm-6 tarnsDetails"
                            style={{
                              display:
                                transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.type === "Schools"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <span className="popTitle">Fee Details</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.paymentDetails?.data
                                  ?.fee_detail}
                            </span>
                          </div>
                          <div
                            className="col-sm-6 tarnsDetails"
                            style={{
                              display:
                                transactionDetail &&
                                transactionDetail?.data?.transactionDetails
                                  ?.type === "Schools"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <span className="popTitle">Student Number</span>
                            <span className="popDesc">
                              {transactionDetail &&
                                transactionDetail?.data?.paymentDetails?.data
                                  ?.student_number}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="trans-details-right">
                        <ul className="transLink">
                          <li>
                            <a
                              className={desideClassName()}
                              // className={`${
                              //   adminPermission.role === "admin" &&
                              //   adminPermission.permissions[0].transactions
                              //     .refund === "view_only"
                              //     ? "disabled"
                              //     : ""
                              // } ${
                              //   transactionDetail &&
                              //   transactionDetail?.data?.transactionDetails
                              //     ?.transactionStatus === "Debited"
                              //     ? "activeBtnColor"
                              //     : "disabled"
                              // }`}
                              style={{ border: "none", position: "relative" }}
                              onClick={() => {
                                refundBtn(
                                  transactionDetail &&
                                    transactionDetail?.data?.transactionDetails
                                      ?.transactionStatus
                                );
                              }}
                            >
                              <i className="icon-icon-refund" /> Refund
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={exportPDf}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="icon-icon-download" /> Export
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* invoice */}
                    {invoiceD && invoiceD !== "0" ? (
                      <>
                        <div className="col-lg-12">
                          <div className="row modified">
                            <div className="col-sm-6 tarnsDetails">
                              <span className="popTitle">Invoice</span>
                              {transactionDetail &&
                                transactionDetail?.data?.invoiceDetails?.items?.map(
                                  (item, index) => {
                                    return (
                                      <span className="popDesc">
                                        {item?.itemName || "--"}x
                                        {item?.qty || "--"} . ₵
                                        {item?.unitRate || "--"}
                                      </span>
                                    );
                                  }
                                )}
                            </div>
                            <div className="col-sm-3 tarnsDetails">
                              <span className="popTitle">Taxes</span>
                              <span className="popDesc">
                                ₵
                                {(transactionDetail &&
                                  transactionDetail?.data?.invoiceDetails
                                    ?.tax) ||
                                  "--"}
                              </span>
                            </div>
                            <div className="col-sm-3 tarnsDetails">
                              <span className="popTitle">Fees</span>
                              <span className="popDesc">
                                ₵
                                {(transactionDetail &&
                                  transactionDetail?.data?.invoiceDetails
                                    ?.fees) ||
                                  "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row modified">
                            <div className="col-sm-6 tarnsDetails">
                              <span className="popTitle">Shipping Address</span>
                              <span className="popDesc">
                                {(transactionDetail &&
                                  transactionDetail?.data?.invoiceDetails
                                    ?.deliveryInfo?.address) ||
                                  "--"}
                              </span>
                            </div>
                            <div className="col-sm-3 tarnsDetails">
                              <span className="popTitle">Delivery Cost</span>
                              <span className="popDesc">
                                ₵
                                {(transactionDetail &&
                                  transactionDetail?.data?.invoiceDetails
                                    ?.deliveryInfo?.cost) ||
                                  "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* invoice */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TranstionIdModel;
