export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const LOGOUT = "LOGOUT";
export const FETCH_ADMIN_PROFILE_REQUEST = 'FETCH_ADMIN_PROFILE_REQUEST';
export const FETCH_ADMIN_PROFILE_SUCCESS = 'FETCH_ADMIN_PROFILE_SUCCESS';
export const FETCH_ADMIN_PROFILE_FAILURE = 'FETCH_ADMIN_PROFILE_FAILURE';

export const USERDETAILS = "USERDETAILS";
export const USERBLOCKSTATUS = "USERBLOCKSTATUS";
export const USERUNBLOCKSTATUS = "USERUNBLOCKSTATUS";
export const USERKYCMANAGMENT = "USERKYCMANAGMENT";
export const INDIVISUALVSBUSINESS = "INDIVISUALVSBUSINESS";
export const RECENTUSERLIST = "RECENTUSERLIST";
export const USERSTATCOUNT = "USERSTATCOUNT";
export const MERCHANTLIST = "MERCHANTLIST";
export const MERCHANTTYPE = "MERCHANTTYPE";
export const MERCHANTSUBCATEGORY = "MERCHANTSUBCATEGORY";
export const UPLOADIMAGE = "UPLOADIMAGE";
export const ADDMERCHANT_SUCCESS = "ADDMERCHANT_SUCCESS";
export const ADDMERCHANT_ERROR = "ADDMERCHANT_ERROR";
export const GET_MERCHANT_DETAIL = "GET_MERCHANT_DETAIL";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const USER_STATUS = "USER_STATUS";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_ERROR = "PAYMENT_STATUS_ERROR";
export const SERVICEPROVIDER_SUCCESS = "SERVICEPROVIDER_SUCCESS";
export const MERCHANT_STATUS = "MERCHANT_STATUS";
export const DEFAULT_FEES_SUCCESS = "DEFAULT_FEES_SUCCESS";
export const DEFAULT_FEES_ERROR = "DEFAULT_FEES_ERROR";
export const GET_DEFAULT_FEES = "GET_DEFAULT_FEES";
export const DEFAULT_SETTING_SUCCESS = "DEFAULT_SETTING_SUCCESS";
export const GET_SETTING_DATA = "GET_SETTING_DATA";
export const GET_MANEY_PROVIDER = "GET_MANEY_PROVIDER";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_CATEGORY_SUBCATEGORY_LIST = "GET_CATEGORY_SUBCATEGORY_LIST";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const UPDATE_CATEGORY_STATUS = "UPDATE_CATEGORY_STATUS";
export const UPDATE_MP_STATUS = "UPDATE_MP_STATUS";
export const ADD_MP = "ADD_MP";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const UPDATE_ADMIN_EMAIL = "UPDATE_ADMIN_EMAIL";
export const ERROR_UPDATE_ADMIN_EMAIL = "ERROR_UPDATE_ADMIN_EMAIL";
export const GET_RECENT_TRANSACTION = "GET_RECENT_TRANSACTION";
export const GET_ALL_TRANSACTION = "GET_ALL_TRANSACTION";
export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID";
export const GET_PAYMENT_VS_REQUEST = "GET_PAYMENT_VS_REQUEST";
export const GET_ALL_TRANSACTION_API = "GET_ALL_TRANSACTION_API";
export const GET_ALL_APPROVELS_API = "GET_ALL_APPROVELS_API";
export const GET_ALL_NAME_APPROVAL_API = "GET_ALL_NAME_APPROVAL_API";
export const GET_TRANSACTION_BY_TYPE_API = "GET_TRANSACTION_BY_TYPE_API";
export const GET_USER_BY_MOBILE_OPERATOR_API =
  "GET_USER_BY_MOBILE_OPERATOR_API";
export const GET_USER_TRANSACTION_COUNT_API = "GET_USER_TRANSACTION_COUNT_API";
export const GET_USER_RECENT_TRASACTION_API = "GET_USER_RECENT_TRASACTION_API";
export const GET_TRASACTION_DETAILS_API = "GET_TRASACTION_DETAILS_API";
export const GET_MERCHANT_BY_REVENUE_API = "GET_MERCHANT_BY_REVENUE_API";
export const GET_USER_DAILY_GROWTH_API = "GET_USER_DAILY_GROWTH_API";
export const GET_PAYMENT_VS_REQUEST_API = "GET_PAYMENT_VS_REQUEST_API";
export const GET_TRANSACTION_DAILY_GROWTH_API =
  "GET_TRANSACTION_DAILY_GROWTH_API";
export const GET_MERCHANT_TRANSACTION_COUNT_API =
  "GET_MERCHANT_TRANSACTION_COUNT_API";
export const GET_MERCHANT_TRANSACTION_LIST_API =
  "GET_MERCHANT_TRANSACTION_LIST_API";
export const GET_USER_REACH_DEVICE_API = "GET_USER_REACH_DEVICE_API";
export const GET_MERCHANT_BY_FEES_API = "GET_MERCHANT_BY_FEES_API";
export const GET_MERCHANT_BY_TRANSACTION_API =
  "GET_MERCHANT_BY_TRANSACTION_API";
export const ADD_APPROVELS_API = "ADD_APPROVELS_APIs";
export const EDIT_PASS_RESPONSE = "EDIT_PASS_RESPONSE";
export const ERROR_UPDATE_ADMIN_PASS = "ERROR_UPDATE_ADMIN_PASS";
export const EXPORT_USER_DATA = "EXPORT_USER_DATA";
export const GET_USER_RECENT_TRASACTION_EXPORT_API =
  "GET_USER_RECENT_TRASACTION_EXPORT_API";
export const MERCHANTLISTEXPORT = "MERCHANTLISTEXPORT";
export const GET_MERCHANT_TRANSACTION_LIST_API_EXPORT =
  "GET_MERCHANT_TRANSACTION_LIST_API_EXPORT";
export const GET_ALL_TRANSACTION_API_EXPORT = "GET_ALL_TRANSACTION_API_EXPORT";
export const GET_TRASACTION_DETAILS_API_EXPORT =
  "GET_TRASACTION_DETAILS_API_EXPORT";
export const GET_TRASACTION_DETAILS_API_EXPORT_CLEAR =
  "GET_TRASACTION_DETAILS_API_EXPORT_CLEAR";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_INVITE_LIST = "GET_INVITE_LIST";
export const GET_INVITE_TRANACTION_USER_LIST =
  "GET_INVITE_TRANACTION_USER_LIST";

// CMS Action type
export const GET_CMS_PRIVACY = "GET_CMS_PRIVACY";
export const UPDATE_PRIVACY = "UPDATE_PRIVACY";
export const UPDATE_PRIVACY_ERROR = "UPDATE_PRIVACY_ERROR";

export const GET_CMS_TERMS = "GET_CMS_TERMS";
export const UPDATE_TERMS = "UPDATE_TERMS";
export const UPDATE_TERMS_ERROR = "UPDATE_TERMS_ERROR";

export const ADD_FAQ_CATEGORY = "ADD_FAQ_CATEGORY";
export const ADD_FAQ_CATEGORY_ERROR = "ADD_FAQ_CATEGORY_ERROR";
export const DELETE_FAQ_CATEGORY = "DELETE_FAQ_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";

export const GET_ALL_TOPICS = "GET_ALL_TOPICS";
export const ADD_TOPICS = "ADD_TOPICS";
export const ADD_TOPICS_ERROR = "ADD_TOPICS_ERROR";
export const EDIT_TOPICS = "EDIT_TOPICS";
export const DELETE_FAQ_TOPIC = "DELETE_FAQ_TOPIC";

export const GET_ALL_FAQ = "GET_ALL_FAQ";
export const GET_ALL_FAQ_BY_TOPIC = "GET_ALL_FAQ_BY_TOPIC";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR";
export const DELETE_QUESTION = "DELETE_QUESTION ";
export const EDIT_QUESTION = "EDIT_QUESTION ";
export const EDIT_QUESTION_ERROR = "EDIT_QUESTION_ERROR";
