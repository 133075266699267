import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Routes from "./routes/routes";
import { checkAdminStatus } from "./utils/adminStatusCheck";
import { validateToken } from "../src/utils/handleToken";
import { logout } from "../src/redux/action/LoginAction/LoginAction";
import "./App.css";
import "../src/assets/css/icon.css";
import "../src/assets/css/font-awesome.min.css";
import { toast } from "react-toastify";

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const entireState = useSelector((state) => state);
 
  const loginSuccesData = useSelector(
    (state) => state.loginData?.loginSuccesData ?? {}
  );

  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  useEffect(() => {
    const hasAccessToken = !!loginSuccesData?.accessToken;
    setIsLoggedOut(!hasAccessToken);
   
  }, [loginSuccesData]);

  useEffect(() => {
    const checkTokenAndAdmin = async () => {
      setIsValidatingToken(true);
      try {
        if (!isLoggedOut) {
          const tokenValidationResult = await validateToken();

          if (tokenValidationResult === null) {
       
            dispatch(logout());
            setIsLoggedOut(true);
            localStorage.removeItem("lastValidPath");
            history.replace("/login");
          } else {
            
            checkAdminStatus();
            localStorage.setItem("lastValidPath", location.pathname);
          }
        }
      } catch (error) {
        console.error("Error in token validation:", error);
        dispatch(logout());
        setIsLoggedOut(true);
        localStorage.removeItem("lastValidPath");
        history.replace("/login");
      } finally {
        setIsValidatingToken(false);
      }
    };

    checkTokenAndAdmin();
  }, [location, dispatch, history, isLoggedOut]);

  useEffect(() => {
    if (!isValidatingToken) {
      if (isLoggedOut && location.pathname !== "/login") {
       
        history.replace("/login");
      } else if (!isLoggedOut && location.pathname === "/login") {
        const lastValidPath =
          localStorage.getItem("lastValidPath") || "/dashboard";
       
        history.replace(lastValidPath);
      }
    }
  }, [isLoggedOut, location, history, isValidatingToken]);

  if (isValidatingToken) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  return <Routes />;
}

export default App;
