import { combineReducers } from "redux";
import loginData from "../reducers/LogInReducers";
import userData from "../reducers/UserReducers";
import merchantData from "../reducers/MerchantReducer";
import settingData from "../reducers/SettingReducer";
import transactionData from "../reducers/TransactionReducer";
import approvalData from "../reducers/ApprovelsReducers";
import invitationData from "../reducers/InvitationsReducers";
import adminProfileData from "../reducers/AdminProfileReducer";
import NameApprovalData from "../reducers/NameApprovalReducer";
import { cmsData, cmsTopic } from "./CmsReducers";

const rootReduser = combineReducers({
  loginData,
  adminProfileData,
  userData,
  merchantData,
  settingData,
  transactionData,
  approvalData,
  invitationData,
  cmsData,
  NameApprovalData,
});

export default rootReduser;
